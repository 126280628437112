import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme'

import { Table } from '@independent-software/typeui/controls/Table'
import { Number } from '@independent-software/typeui/formatters/Number'

import { IMapElement } from '../../api/services/MapApi';

interface IProps {
  className?: string;
  element: IMapElement;
}

class InfoTableBase extends React.Component<IProps> {
  render = () => {
    const p = this.props;
    const tags = p.element.tags;
    return (
      <div className={p.className}>
        <Table striped transparent>
          <tbody>
            <tr><td>District</td><td>{tags["::ADM3_EN"]}</td></tr>
            <tr><td>Village</td><td>{tags["::ADM4_EN"]}</td></tr>
            {tags["::area_m2"]        && <tr><td>Area</td><td><Number value={tags["::area_m2"]} decimals={0}/></td></tr>}
            {tags["::species"]        && <tr><td>Species</td><td>{tags["::species"]}</td></tr>}
            {tags["::company"]        && <tr><td>Company</td><td>{tags["::company"]}</td></tr>}
            {tags["::owner"]          && <tr><td>Owner</td><td>{tags["::owner"]}</td></tr>}
            {tags["::farm_name"]      && <tr><td>Farm name</td><td>{tags["::farm_name"]}</td></tr>}
            {tags["::vol_m3"]         && <tr><td>Volume</td><td><Number value={tags["::vol_m3"]} decimals={0}/></td></tr>}
            {tags["::culture_type"]   && <tr><td>Culture type</td><td>{tags["::culture_type"]}</td></tr>}
            {tags["::structure_type"] && <tr><td>Structure type</td><td>{tags["::structure_type"]}</td></tr>}
            {tags["::use"]            && <tr><td>Use</td><td>{tags["::use"]}</td></tr>}
          </tbody>
        </Table>      
      </div>
    );
  }
}

const InfoTable = styled(InfoTableBase)`
  font-family: Roboto;
  td:first-child {
    width: 100px;
    color: #888;    
  }
  td:last-child {
    max-width: 140px;
    width: 140px;
    overflow: hidden;
    text-overflow: ellipsis;     
  }
`

export { InfoTable }