import * as React from 'react';

import { Float } from "@independent-software/typeui/controls/Types"
import { Number } from '@independent-software/typeui/formatters/Number';

import { Tag } from "./Tag";
import { FloatDialog } from '../dialogs/FloatDialog';


class FloatTag extends Tag {
  defaultValue: number;
  min: number;
  max: number;

  constructor(id: string, name: string, description: string, defaultValue: number, min: number, max: number) {
    super(id, name, description);
    this.defaultValue = defaultValue;
    this.min = min;
    this.max = max;
    this.value = [ "", "" ];
  }

  clone = () => {
    const t = new FloatTag(this.id, this.name, this.description, this.defaultValue, this.min, this.max);
    t.setValues(this.value.slice());
    return t;
  }

  getClass = () => "FLOAT";

  isValueValid = (value: any): boolean => !isNaN(value - parseFloat(value));

  isValid = (values: string[]): boolean => {
    return (this.isValueValid(values[0]) && values[1] == "")
    || (this.isValueValid(values[1]) && values[0] == "")
    || (this.isValueValid(values[0]) && this.isValueValid(values[1]));    
  }

  public toSPEL = (): string[] => {
    const elements: string[] = [];
    if(this.value[0]) elements.push(`tags['${this.id}'] >= ${this.value[0]}`);
    if(this.value[1]) elements.push(`tags['${this.id}'] <= ${this.value[1]}`);
    return elements;
  }  

  format = (value: any): React.ReactNode => <Number value={value} decimals={2}/>;

  getAlignment = (): Float => 'right';

  toLabel = (): React.ReactNode => {
    if(this.value[0] == "") return <>up to <Number value={this.value[1]} decimals={0}/></>
    if(this.value[1] == "") return <><Number value={this.value[0]} decimals={0}/>+</>
    return <><Number value={this.value[0]} decimals={0}/> - <Number value={this.value[1]} decimals={0}/></>;
  }

  getDialog = (tags: Tag[], onConfirm: () => void, onCancel: () => void): React.ReactNode => 
    <FloatDialog tag={this} tags={tags} onConfirm={onConfirm} onCancel={onCancel}/> 

  /* getEditor = () => FloatEditor; */

  isEditable = () => true;
}

export { FloatTag }
