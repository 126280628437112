import * as React from 'react';

import { Form } from '@independent-software/typeui/controls/Form';
import { Input } from '@independent-software/typeui/controls/Input';
import { Password } from '@independent-software/typeui/modules/Password';

interface ISigninForm {
  email: string;
  password: string;
}

interface ISigninFormProps {
  /** Initial form data. */
  data: ISigninForm;
  /** Called whenever form changes. */
  onChange: (data: ISigninForm, forceupdate: boolean) => void;
  /** Called whenever a field validates. Returns validation state for whole form. */
  onValidate: (valid: boolean) => void;
  /** Are form controls disabled? */
  disabled?: boolean;
  dirty?: boolean;
  onSubmit: () => void;
}

interface ISigninFormState {
  /** Current fom data */
  data: ISigninForm;
}

class SigninForm extends React.Component<ISigninFormProps, ISigninFormState> {
  constructor(props: ISigninFormProps) {
    super(props);

    this.state = {
      data: props.data
    };    
  }

  private handleKeyPress = (e: React.KeyboardEvent) => {
    if(e.charCode == 13) this.props.onSubmit();
  }
  
  render() {
    let p = this.props;
    return (
      <div onKeyPress={this.handleKeyPress}>
        <Form data={this.state.data} dirty={p.dirty} onChange={p.onChange} onValidate={p.onValidate}>
          <Form.Field 
            required={{message: "Email is required."}}
            name="email" 
            value={this.state.data.email}
            control={(<Input disabled={p.disabled} type="text" placeholder="Email" fluid/>)}
            hint="Enter your email."
            />
          <Form.Field 
            name="password" 
            value={this.state.data.password}
            control={(<Password disabled={p.disabled} placeholder="Password" fluid/>)}
            hint="Enter your password."
            />            
        </Form>
      </div>
    )
  }
}

export { SigninForm, ISigninForm };
