import { Api } from './Api';

/** 
 * API request for Histogram retrieval.
 */
interface IHistogramsRequest {
  filter: string;
  histograms: { 
    [key: string]: {
      tagId: string
    }
  }
}

interface IHistogramsResponse {
  results: { 
    [key: string]: [
      {
        count: number;
        binStart: number;
        binEnd: number;
      }
    ]
  }
}

class HistogramApi {
  
  public static histogram(request: IHistogramsRequest, access_token: string, signal?: AbortSignal): Promise<IHistogramsResponse> {
    return Api.ApiPost<IHistogramsResponse>(`histogram`, request, access_token, signal);
  }   

}

export { HistogramApi, IHistogramsRequest, IHistogramsResponse }
