import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme'

import { Dialog } from '@independent-software/typeui/controls/Dialog';
import { Button } from '@independent-software/typeui/controls/Button';
import { Flex } from '@independent-software/typeui/controls/Flex';
import { Form } from '@independent-software/typeui/controls/Form';
import { Input } from '@independent-software/typeui/controls/Input';
import { Divider } from '@independent-software/typeui/controls/Divider';

import { Tag } from '../models/Tag';
import { LoadableHistogram } from '../../components/Histogram/LoadableHistogram';

interface IProps {
  className?: string;
  tags: Tag[];
  tag: Tag;
  onConfirm: () => void;
  onCancel: () => void;
}

interface IState {
  value: any[];
}

class FloatDialog extends React.Component<IProps, IState> {

  constructor(props: IProps) {
    super(props);
    this.state = {
      value: props.tag.value.slice()
    }
  }

  private handleOK = () => {
    if(this.props.tag.isValid(this.state.value)) {
      this.props.tag.setValues(this.state.value);
      this.props.onConfirm();
    }
  }

  // If a key is pressed, over the entire Modal, then react to it.
  private handleKeyDown = (e: React.KeyboardEvent) => {
    if(e.code == "Enter") this.handleOK();
    if(e.code == "Escape") this.props.onCancel();
  }  

  private handleChangeValue = (index: number, s: string) => {
    this.state.value[index] = s;
    this.setState({
      value: this.state.value
    });
  }  

  handleHistogramClick = (start: number, end: number) => {
    this.setState({
      value: [start, end]
    });
  }

  render = () => {
    const p = this.props;
    return (
      <div className={p.className} onKeyDown={this.handleKeyDown}>
        <Dialog.Header>{p.tag.name}</Dialog.Header>

        <Dialog.Content>

          <HistogramContainer>
            <LoadableHistogram tags={p.tags} tag={p.tag} start={this.state.value[0]} end={this.state.value[1]} onClick={this.handleHistogramClick}/>
          </HistogramContainer>

          <Divider hidden/>

          <Flex.Columns count={2}>
            <Form.Uncontrolled label="Lower limit" hint="Please enter the lower limit.">
              <Input fluid placeholder="Enter value" value={this.state.value[0]} error={this.state.value[0] != "" && !p.tag.isValueValid(this.state.value[0])} onChange={(s) => this.handleChangeValue(0,s)}/>
            </Form.Uncontrolled>
            <Form.Uncontrolled label="Upper limit" hint="Please enter the upper limit.">
              <Input fluid placeholder="Enter value" value={this.state.value[1]} error={this.state.value[1] != "" && !p.tag.isValueValid(this.state.value[1])} onChange={(s) => this.handleChangeValue(1,s)}/>
            </Form.Uncontrolled>
          </Flex.Columns>
        </Dialog.Content>

        <Dialog.Footer>
          <div style={{ display: 'flex', justifyContent: 'space-between'}}>
            <Button onClick={p.onCancel}>Cancel</Button>
            <Button primary disabled={!p.tag.isValid(this.state.value)} onClick={this.handleOK}>OK</Button>
          </div>
        </Dialog.Footer>
      </div>
    );
  }
}

const HistogramContainer = styled('div')`
  position: relative;
  height: 200px;
`

export { FloatDialog }
