import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme'
import { TagChip } from './TagChip';
import { TagSelector } from './TagSelector';
import { ITagType, TTagTypeCollection } from '../../api/models/TagType';
import { Tag } from '../../api/models/Tag';
import { FilterContext } from '../../FilterContext';

interface IProps {
  /** @ignore */
  className?: string;
}

class TagBarBase extends React.Component<IProps> {
  render = () => {
    const p = this.props;
    return (
      <div className={p.className}>
        <FilterContext.Consumer>
          {filter => 
          <>
            <TagSelector
              tagTypeCollection={filter.tagTypeCollection}
              tags={filter.tags}
              onAddTag={filter.addTag}
              onCategorizeTag={() => {}}
            />          
            <TagChips>
              {filter.tags.map((t, idx) => <TagChip key={idx} tag={t} onClick={() => filter.editTag(t)} onRemove={() => filter.removeTag(t)}/>)}
            </TagChips>
          </>}
        </FilterContext.Consumer>
      </div>
    );
  }
}

const TagBar = styled(TagBarBase)`
  display: flex;
  flex: 1;
  flex-direction: row;
  height: 40px;
  background: white;
  line-height: 40px;
  box-shadow: 0 0 2px rgb(0,0,0,0.8);
`

const TagChips = styled('div')`
  display: flex;
  flex-direction: row;
  padding-top: 8px;  
`

export { TagBar }
