import type { SymbolLayer } from 'react-map-gl';

const ClustersSymbolLayer: SymbolLayer = {
  id: "cluster-labels",
  type: "symbol",
  layout: {
    "text-field": [
      "case",
      [">=", ["get", "count"], 1000000 ], ["concat", [ "round", ["/", ["get", "count"], 1000000]], "M"],
      [">",  ["get", "count"],    1000 ], ["concat", [ "round", ["/", ["get", "count"],    1000]], "K"],
      ["get", "count"]
    ],
    "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
    "text-size": 14
  }
};

export { ClustersSymbolLayer }
