import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme'
import { Human } from '@independent-software/typeui/formatters/Human';

interface IProps {
  /** @ignore */
  className?: string;
  left: number;
  value: number;
}

class HistogramTickBase extends React.Component<IProps> {
  render = () => {
    const p = this.props;
    return (
      <div className={p.className}>
        <span><Human value={p.value}/></span>
      </div>
    );
  }
}

const HistogramTick = styled(HistogramTickBase)`
  position: absolute;
  box-sizing: border-box;
  left: ${p => p.left}%;
  top: 0;
  width: 1px;
  height: 5px;
  border-left: solid 1px #aaa;

  span {
    display: block;
    position: absolute;
    top: 5px;
    left: 0;
    transform: translate(-50%, 0);
    font-size: 12px;
    color: #333;
    user-select: none;
  }
`

export { HistogramTick }
