import * as React from 'react';
import { Float } from "@independent-software/typeui/controls/Types"

abstract class Tag {
  public id: string;
  public name: string;
  public description: string;
  // A tag value is a series of values. Text tags only have one value,
  // while numeric tags have two (low and high), and nav tags have several.
  public value: any[];
  // Is tag currently in filter?
  public selected: boolean;

  constructor(id: string, name: string, description: string) {
    this.id = id;
    this.name = name;
    this.description = description;
    this.selected = false;
  }

  clone: () => Tag;

  /**
   * Get classname of Tag, e.g. "FLOAT".
   */
  getClass: () => string;

  /**
   * Retrieve value by index.
   */
  public getValue = (index: number): any => {
    return this.value[index];
  }

  /**
   * Set value by index.
   */
  public setValue = (index: number, v: any) => {
    this.value[index] = v;
  }

  /**
   * Set all tag values.
   */
  public setValues = (value: string[]) => this.value = value;  

  /**
   * Clear current value.
   */
  public clear = () => {
    this.value.fill("");
  }
  
  /**
   * Is the given value valid for this tag?
   */
  isValueValid: (value: any) => boolean;

  /** 
   * Is the given value set valid for this tag?
   */
  isValid: (values: any[]) => boolean;

  /**
   * Convert current value of Tag to SPEL.
   */
  toSPEL: () => string[];

  /**
   * Format a value using this tag's formatting rules.
   */
  format: (value: any) => React.ReactNode;

  /**
   * How should this Tag be aligned in a table column?
   */
  getAlignment: () => Float;

  /**
   * Return tag name and value, suitable for placing in a chip.
   */
  toLabel: () => React.ReactNode;

  /**
   * Return a modal Dialog to deal with Tag filter input.
   */
  getDialog: (tags: Tag[], onConfirm: () => void, onCancel: () => void) => React.ReactNode;

  /**
   * Return an editor for the Tag.
   */
  getEditor: () => any;

  /**
   * Is this tag editable?
   */
  isEditable: () => boolean;
}


export { Tag }
