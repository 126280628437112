import type { FillLayer } from 'react-map-gl';

const PondsSelectionLayer: FillLayer = {
  id: "ponds-selection",
  minzoom: 15,
  type:"fill",
  paint: { 
    "fill-color": "green",
    "fill-opacity": 0.5
  }
};

export { PondsSelectionLayer }
