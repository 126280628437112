import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme'
import { css } from 'styled-components';

import { TagListEntry } from './TagListEntry';
import { ITagType, TTagTypeCollection } from '../../api/models/TagType';
import { Tag } from '../../api/models/Tag';

interface IProps {
  /** @ignore */
  className?: string;
  /** Available tags */
  tagTypeCollection: TTagTypeCollection; 
  /** Currently selected tags */
  tags: Tag[];
  /** Is TagList currently open? */
  open: boolean;
  /** Query string */
  q: string;
  /** Currently selected entry (0-based) */
  selectionIndex: number;
  /** Should the panel open upwards? */
  upwards: boolean;
  /** Fired when a tag type is clicked. */
  onClickTag: (tagType: ITagType) => void;
  /** Fired when a tag type's categorization icon is clicked. */
  onCategorizeTag: (tagType: ITagType) => void;
}

class TagListBase extends React.Component<IProps> {
  render = () => {
    const p = this.props;
    return (
      <div className={p.className}>
        {p.tagTypeCollection
          .map((tagType, idx) => <TagListEntry 
            key={idx} 
            tagType={tagType} 
            q={p.q} 
            highlight={p.selectionIndex == idx}
            selected={p.tags.some(t => t.id == tagType.id)}
            onClick={() => p.onClickTag(tagType)}
            onCategorize={() => p.onCategorizeTag(tagType)}
          />)}
      </div>
    );
  }
}

const TagList = styled(TagListBase)`
  z-index: 10000;
  position: absolute;
  display: flex;
  flex-direction: column;
  left: 0;
  ${p => p.upwards && css`bottom: 40px`}
  ${p => !p.upwards && css`top: 40px`}
  width: 250px;
  transition: height ease-in-out .25s;
  ${p => p.open && css`height: 35vh`}
  ${p => !p.open && css`height: 0`}
  background: white;
  ${p => p.upwards && css`box-shadow: 1px -1px 2px rgb(0,0,0,0.8);`}
  ${p => !p.upwards && css`box-shadow: 1px 1px 2px rgb(0,0,0,0.8);`}
  overflow-y: auto;
`

export { TagList }
