import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme'
import { HistogramBar } from './HistogramBar';
import { HistogramTick } from './HistogramTick';
import { Icon } from '@independent-software/typeui/controls/Icon';

interface IHistogramBin {
  count?: number;
  binStart?: number;
  binEnd?: number;
}

interface IProps {
  /** @ignore */
  className?: string;
  /** Value for each bin. */
  bins: IHistogramBin[];
  /** Index of first selected bar (0-based): */
  selectionStart?: number;
  /** Length of selection: */
  selectionLength?: number;
  /** Show axis? */
  showAxis?: boolean;
  /** show values on hover? */
  showValues?: boolean;
  /** Currently loading? */
  loading?: boolean;
  /** Optional click event includes the number of the bar clicked (0-based): */
  onClick?: (idx: number) => void;
  /** Optionally adds a button to zoom out. */
  onZoomout?: () => void;
}

class HistogramBase extends React.Component<IProps> {
  render = () => {
    const p = this.props;

    // Find tallest bar. This is used to calculate the percentage height
    // of each bar.
    const maxHeight = Math.max(...p.bins.map((b) => b.count));

    return (
      <div className={p.className}>
        <ChartArea>
          <Indent>
            <BarArea>
              {p.bins.map((bin: IHistogramBin, idx: number) => {
                return <HistogramBar 
                  key={idx} 
                  showValues={p.showValues}
                  value={bin.count}                  // Bar's actual value
                  height={100*bin.count/maxHeight}   // Bar's percentage height
                  selected={idx >= p.selectionStart && idx < p.selectionStart + p.selectionLength}
                  onClick={p.onClick ? () => p.onClick(idx) : null}
                  loading={p.loading}
                />
              })}
            </BarArea>
          </Indent>
        </ChartArea>
        {p.showAxis && <AxisArea>
          <Indent>
            {p.bins.map((bin, idx) => 
              <HistogramTick key={idx} left={100 * idx / p.bins.length} value={bin.binStart}/>
            )}
            {p.bins.length > 0 && 
              <HistogramTick left={100} value={p.bins[p.bins.length-1].binEnd}/>
            }            
          </Indent>
        </AxisArea>}
        {p.onZoomout && !p.loading && <ZoomHolder>
          <Icon name="times" onClick={p.onZoomout}/>
        </ZoomHolder>}
      </div>
    );
  }
}

const ChartArea = styled('div')`
  position: relative;
  border-bottom: solid 1px #aaa;
  flex: 1;
`

const AxisArea = styled('div')`
  position: relative;
  flex: 0;
  height: 32px;
  min-height: 32px;
  box-sizing: border-box;
`

const Indent = styled('div')`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 20%;
  right: 20%;
`

const BarArea = styled('div')`
  // BarArea takes up an absolute position:
  position: absolute;
  width: 100%;
  height: 100%;
  background: white;
  // Bars are placed using flexbox:
  display: flex;
  flex-direction: row;  
  justify-content: center;
  align-items: flex-end;
  overflow-y: hidden;
`

const ZoomHolder = styled('div')`
  position: absolute;
  right: 0;
  top: 0;
`

const Histogram = styled(HistogramBase)`
  // Histogram fills available space:
  width: 100%;
  height: 100%;
  background: white;
  display: flex;
  flex-direction: column;
`

export { Histogram, IHistogramBin }