import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme'
import { Input } from '@independent-software/typeui/controls/Input';

interface IProps {
  /** @ignore */
  className?: string;
  /** Current search query value. */
  q: string;
  /** Fired when search query changes. */
  onChange: (q: string) => void;
  /** Fired when up or down arrow is pressed. */
  onArrow: (dir: number) => void;
  /** Fired when enter key is pressed. */
  onEnter: () => void;
  /** Fired when escape key is pressed. */
  onEscape: () => void;
}

/**
 * This component shows the current tag search query in an input box,
 * and allows editing it.  Special detection is in place for up/down
 * arrow keys and enter, which allow changing the current tag selection
 * and picking a tag from the list, respectively.
 */ 
class FilterTypeinBase extends React.Component<IProps> {
  private wrapperRef: HTMLDivElement;
  
  // 
  // When list opens, focus on input box.
  //
  componentDidMount(): void {
    setTimeout(() => {
      // Put focus in text box.
      if(!this.wrapperRef) return;
      this.wrapperRef.querySelector('input').focus();
    }, 100);
  }  

  //
  // If the up/down arrows or enter are pressed, notify parent.
  //
  private handleKeyDown = (e: React.KeyboardEvent) => {
    switch(e.code) {
      case 'ArrowDown':
        e.preventDefault();
        this.props.onArrow(1);  
        break;
      case 'ArrowUp':
        e.preventDefault();
        this.props.onArrow(-1);
        break;
      case 'Enter':
        e.preventDefault();
        this.props.onEnter();
        break;
      case 'Escape':
        e.preventDefault();
        this.props.onEscape();
        break;
    }
  }

  render = () => {
    const p = this.props;
    return (
      <div className={p.className} ref={(el:any) => this.wrapperRef = el} onKeyDown={(e: React.KeyboardEvent) => this.handleKeyDown(e)}>
        <Input 
          icon="search" fluid iconPosition="left" transparent type="text" 
          placeholder="Type to search"
          value={p.q} onChange={p.onChange}/>
      </div>
    );
  }
}

const FilterTypein = styled(FilterTypeinBase)`
  background: #fff;
`

export { FilterTypein }