import type { CircleLayer } from 'react-map-gl';
import { CLUSTER_SIZE, CLUSTER_SIZE_STEP } from './ClusterConstants';

const ClustersCircleLayer: CircleLayer = {
  id: "cluster-circles",
  type: "circle",
  paint: {
    "circle-radius": [
      "+", 
        ["*", 
          [ "log10", [ "get", "count" ] ], 
          CLUSTER_SIZE_STEP], 
      CLUSTER_SIZE - 2
    ],
    "circle-color": "white"
  }
};

export { ClustersCircleLayer }
