import axios from 'axios';
import { App } from '../../App';

class Api {
  /** 
   * Perform a GET request of type T at given URL. This method takes care of
   * sending the access token along.
   */
  static async Get<T> (url: string, access_token: string, signal?: AbortSignal): Promise<T> {
    const response = await axios.get<T>(url, {
      signal: signal,
      headers: { Authorization: `Bearer ${access_token}` }
    });
    return response.data;
  }

  static async ApiGet<T>(url: string, access_token: string, signal?: AbortSignal): Promise<T> {
    return this.Get(App.config.apiURL + url, access_token, signal);
  }

  static async AdminGet<T>(url: string, access_token: string, signal?: AbortSignal): Promise<T> {
    return this.Get(App.config.adminURL + url, access_token, signal);
  }

  /** 
   * Perform a POST request of type T at given URL. This method takes care of
   * sending the access token along.
   */
  static async Post<T>(url: string, request: any, access_token: string, signal?: AbortSignal): Promise<T> {
    const response = await axios.post<T>(url, request, {
      signal: signal,
      headers: { Authorization: `Bearer ${access_token}` }
    });
    return response.data;
  }  

  static async ApiPost<T>(url: string, request: any, access_token: string, signal?: AbortSignal): Promise<T> {
    return this.Post(App.config.apiURL + url, request, access_token, signal);
  }

  static async AdminPost<T>(url: string, request: any, access_token: string, signal?: AbortSignal): Promise<T> {
    return this.Post(App.config.adminURL + url, request, access_token, signal);
  }
}

export { Api }
