import * as React from 'react';
import { AuthContext, IAuth } from './AuthContext';

interface IProps {
  public: () => React.ReactNode;
  private: () => React.ReactNode;
}

class Auth extends React.Component<IProps, IAuth> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      id: null,
      name: null,
      email: null,
      access_token: null,
      refresh_token: null,
      rights: [],
      signin: this.signin,
      signout: this.signout
    };
  }

  componentDidMount = (): void => {
    // Try to load Auth from session storage.
    const authStr = sessionStorage.getItem('auth');
    if(!authStr) return null;
    const auth = JSON.parse(authStr);
    this.setState({ ...auth });
  }

  /**
   * Returns true if authenticated user is authorized for specified right.
   * @param right 
   */
  hasRight = (right: string | boolean) => {
    if(right === true) return true;
    return this.state.rights.includes(right as string);
  }  

  signin = (id: string, name: string, email: string, access_token: string, refresh_token: string, rights: string[]) => {
    this.setState({
      id,
      name,
      email,
      access_token,
      refresh_token,
      rights
    }, () => {
      // Store auth in session:
      sessionStorage.setItem('auth', JSON.stringify(this.state));
    });
  }

  signout = () => {
    this.setState({ id: null, access_token: null });
    // Remove auth from session:
    sessionStorage.removeItem('auth');
  }

  render = () => {
    return (
      <AuthContext.Provider value={this.state}>
        {this.state.id == null && this.props.public()}
        {this.state.id != null && this.props.private()}
      </AuthContext.Provider>
    );
  }
}

export { Auth };
