import { Api } from './Api';

interface IUser {
  extId: string;
  username: string;
  fullName: string;
  phone: string;
  email: string;
  createdAt: string;
  updatedAt: string;
  enabled: boolean;
  roles: string[];
  infoUpdatedAt: string;
}

class UserApi {
  static me = (access_token: string): Promise<IUser> => {
    return Api.ApiGet<IUser>("user/me", access_token);
  }
}

export { UserApi, IUser };
