import * as React from 'react';
import AwesomeDebouncePromise from 'awesome-debounce-promise';

import { Histogram, IHistogramBin } from './Histogram';
import { Loader } from '@independent-software/typeui/controls/Loader';
import { AuthContext } from '../../AuthContext';
import { Tag } from '../../api/models/Tag';
import { HistogramApi, IHistogramsRequest } from '../../api/services/HistogramApi';
import { Spel } from '../../api/services/Spel';

interface IProps {
  tags: Tag[];
  /** Tag (e.g. "::area_m2") to request a histogram for. */
  tag: Tag;
  start?: number;
  end?: number;
  onClick: (start: number, end: number) => void;
}

interface IState {
  bins: IHistogramBin[];
  loading: boolean;
  undo: any[][];
}

class LoadableHistogram extends React.Component<IProps, IState> {
  declare context: React.ContextType<typeof AuthContext>;
  private controller: AbortController = null;

  state: IState = {
    bins: [],
    loading: true,
    undo: []
  }

  componentDidMount = () => {
    this.load();
  }

  componentDidUpdate = (prevProps: IProps) => {
    // Only reload histogram if start or end have changed:
    if(prevProps.start != this.props.start || prevProps.end != this.props.end) {
      this.loadDebounced();
    }
  }

  componentWillUnmount = () => {
    // Cancel any running Histogram API query.
    if(this.controller) this.controller.abort();
  }

  load = async () => {
    const p = this.props;
    this.setState({ loading: true });

    // If an AbortController instance is available, then cancel the pending
    // request.
    if(this.controller) this.controller.abort();

    // Create a new AbortController.
    this.controller = new AbortController();

    // Create a local filter.
    p.tag.setValues([p.start.toString(), p.end.toString()]);
    const localTags: Tag[] = [ ...p.tags.slice().filter(t => t.id !== p.tag.id), p.tag];
    const filterString: string = Spel.getSpel(localTags);

    const request: IHistogramsRequest = {
      filter: filterString,
      histograms: {
        data: {
          tagId: p.tag.id
        }
      }
    }

    HistogramApi.histogram(request, this.context.access_token, this.controller.signal)
    .then(response => {
      this.setState({
        bins: response.results["data"],
        loading: false
      });
    })
    .catch((error: Error) => {
      // Show error only if it isn't a "canceled" error (which is normal).
      if(error.message != 'canceled') {
        console.log("Histogram API error:", error);
      }
    });
  }

  private loadDebounced = AwesomeDebouncePromise(this.load, 350);

  handleClick = (idx: number) => {
    const bin = this.state.bins[idx];
    this.state.undo.push([ this.props.start, this.props.end ]);
    this.setState({
      undo: this.state.undo
    });
    this.props.onClick(bin.binStart, bin.binEnd);
  }

  handleZoomout = () => {
    if(this.state.undo.length == 0) return;
    const values = this.state.undo.pop();
    this.setState({ undo: this.state.undo });
    this.props.onClick(values[0], values[1]);
  }

  render = () => {
    return (
      <Histogram 
        bins={this.state.bins} 
        loading={this.state.loading}
        selectionStart={0} 
        selectionLength={0}
        onClick={this.handleClick}
        onZoomout={this.state.undo.length > 0 ? this.handleZoomout : null}
        showAxis
        showValues
      />
    );
  }
}

LoadableHistogram.contextType = AuthContext;

export { LoadableHistogram }
