import { Api } from './Api';
import { TTagTypeCollection } from '../models/TagType';

class TagApi {
  /** 
   * Returns an array of all tag types, sorted alphabetically. 
   */
  static listTagTypes = (access_token: string): Promise<TTagTypeCollection> => {
    return Api.ApiGet<TTagTypeCollection>("tag/type", access_token)
    .then(res => res
        // Add NAV tag to replace ADMx:
        // .concat([ new NavTag() ])
        // Sort all tag types:
        .sort((t1, t2) => (t1.name < t2.name ? -1 : (t1.name > t2.name ? 1 : 0)))
    );
  }
}

export { TagApi };
