import { Api } from './Api';
import { Geometry } from "geojson"; 

interface IMapSearchRequest {
  upperLeft: number[],
  lowerRight: number[],
  zoom: number,
  filter: string;
  returnTags: string[];
}

interface IMapSearchResponse {
  features: IMapElement[]
};

interface IMapElement {
  id?: string;
  clusterCount: number;
  geometry: Geometry,
  tags: { 
    [tag: string]: any;
  };
  checked?: boolean;
  selected?: boolean;
  category?: number;
}

class MapApi {
  public static mapSearch(request: IMapSearchRequest, access_token: string, signal?: AbortSignal): Promise<IMapSearchResponse> {
    return Api.ApiPost<IMapSearchResponse>(`map/search`, request, access_token, signal);
  }   
}

export { MapApi, IMapSearchRequest, IMapSearchResponse, IMapElement }
