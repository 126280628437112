import * as React from 'react';

import { Float } from "@independent-software/typeui/controls/Types"

import { Tag } from "./Tag";
import { ShortTextDialog } from '../dialogs/ShortTextDialog';

class ShortTextTag extends Tag {
  defaultValue: string;

  constructor(id: string, name: string, description: string, defaultValue: string) {
    super(id, name, description);
    this.defaultValue = defaultValue;
    this.value = [""];
  }

  clone = () => {
    const t = new ShortTextTag(this.id, this.name, this.description, this.defaultValue);
    t.setValues(this.value.slice());
    return t;
  }  

  getClass = () => "SHORT_TEXT";

  isValueValid = (value: any): boolean => value.trim().length > 0;

  isValid = (value: any[]): boolean => value[0].trim().length > 0;

  toSPEL = (): string[] => [`tags['${this.id}'] == '${this.value[0]}'`];

  format = (value: any): React.ReactNode => value;

  getAlignment = (): Float => 'left';

  toLabel = (): React.ReactNode => this.value[0];

  getModal = (tags: Tag[], onConfirm: (value: any[]) => void, onCancel: () => void): React.ReactNode => 
    <ShortTextDialog tags={tags} tag={this} onConfirm={onConfirm} onCancel={onCancel}/> 

  /* getEditor = () => ShortTextEditor; */

  isEditable = () => {
    if(this.id.startsWith("::ADM")) return false;
    return true;
  }
}

export { ShortTextTag }
