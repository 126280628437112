import * as React from 'react'
import styled from '@independent-software/typeui/styles/Theme'

import { SigninComponent } from './SigninComponent';

class SigninPage extends React.Component {
  // PassW0rd!123
  render = () => {
    return (
      <Page>
        <Content>
          <Box>
            <Logo><use xlinkHref={"sprites.svg#aquascape"}></use></Logo>
            <SigninComponent/>
          </Box>
        </Content>
      </Page>
    );
  }  
}

const Content = styled('div')`
  position: absolute;
  width: 400px;
  left: 50%;
  margin-left: -200px;
  padding-top: 20vh;
`

const Logo = styled('svg')`
  width: 350px;
  height: 100px;
  margin-bottom: 40px;
`

const Box = styled('div')`
  background: #fff;
  padding: 25px 25px 25px 25px;
  border-radius: 4px;
`

const Page = styled('div')`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-image: linear-gradient(rgb(21, 71, 121), rgb(16, 108, 200));
`

export { SigninPage }
