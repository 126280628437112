import { Tag } from "../models/Tag";

class Spel {
  static getSpel = (tags: Tag[]) => {
    // Build filter.
    let elements: string[] = [];
    tags.forEach(tag => {
      elements = elements.concat(tag.toSPEL());
    });
    let filter = elements.join(' && ');
    if(filter == "") filter = null;
    return filter;    
  }
}

export { Spel }
