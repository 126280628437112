import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme';
import { Histogram, IHistogramBin } from '../Histogram/Histogram';

interface IProps {
  className?: string;
  loading: boolean;
  title: string;
  bins: IHistogramBin[];
  onClick: () => void;
}

class SmallHistogramBase extends React.Component<IProps> {
  render = () => {
    const p = this.props;
    return (
      <div className={p.className}>
        <HistogramTitle onClick={p.onClick}>{p.title}</HistogramTitle>
        <HistogramArea>
          <Histogram
            loading={p.loading}
            bins={p.bins} 
            selectionStart={0} 
            selectionLength={0}
          />              
        </HistogramArea>        
      </div>
    );
  }
}

const SmallHistogram = styled(SmallHistogramBase)`
  box-sizing: border-box;
  width: 47%;
  height: 150px;
  border: solid 1px #aaa;
  margin: 5px;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  background: white;
`

const HistogramTitle = styled('div')`
  flex: 0;
  height: 32px;
  min-height: 32px;
  margin-bottom: 5px;
  line-height: 32px;
  padding: 0 8px 0 8px;
  background: #eee;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  user-select: none;
  cursor: pointer;
`

const HistogramArea = styled('div')`
  position: relative;
  padding: 4px;
  flex: 1;
`

export { SmallHistogram }
