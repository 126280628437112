import type { FillLayer } from 'react-map-gl';

const PondsFillLayer: FillLayer = {
  id: "ponds-fill",
  type:"fill",
  minzoom: 15,
  paint: { 
    "fill-color": "steelblue",
    "fill-opacity": 0.5
  }
};

export { PondsFillLayer }
