import type { FillLayer } from 'react-map-gl';

const PondsHoverLayer: FillLayer = {
  id: "ponds-hover",
  type:"fill",
  minzoom: 15,
  paint: { 
    "fill-color": "gold",
    "fill-opacity": 0.7
  }
};

export { PondsHoverLayer }
