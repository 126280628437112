import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme'

import { Icon } from '@independent-software/typeui/controls/Icon';
import { IconStyled } from '@independent-software/typeui/controls/Icon/Icon';

import { Tag } from '../../api/models/Tag';

interface IProps {
  /** @ignore */
  className?: string;
  /** Tag */
  tag: Tag;
  /** Fired when tag is clicked */
  onClick: () => void;
  /** Fired when tag is removed */
  onRemove: () => void;
}

class TagChipBase extends React.Component<IProps> {
  render = () => {
    const p = this.props;
    return (
      <div className={p.className}>
        <div onClick={p.onClick}>
          <TagName>{p.tag.name}</TagName> 
          <TagValue>{p.tag.toLabel()}</TagValue>
        </div>
        <Icon url="sprites.svg#delete-tag" onClick={p.onRemove}/>
      </div>
    );
  }
}

const TagChip = styled(TagChipBase)`
  display: flex;
  flex-direction: row;
  align-items: center;
  background: white;
  border-radius: 12px;
  border: solid 1px #37ACE3;
  white-space: nowrap;
  margin-left: 10px;
  height: 24px;
  line-height: 24px;
  box-sizing: border-box;
  padding: 0 10px 0 10px;
  user-select: none;
  font-size: 12px;
  cursor: pointer;
  ${IconStyled} {
    fill: #C5C5C5;
    margin-left: 5px;
    cursor: pointer;
    &:hover {
      fill: darkred;
    }
  }
  transition: background-color ease-in-out 0.25s;
  &:hover {
    background-color: #efefef;
  }
`

const TagName = styled('span')`
  margin-right: 6px;
  font-style: italic;
  color: #1E3663;
`

const TagValue = styled('span')`
  font-weight: 500;
  color: #1E3663;
`

export { TagChip }