import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme';

import { Message } from '@independent-software/typeui/controls/Message';

import { FilterContext, IFilter } from '../../FilterContext';
import { HistogramApi, IHistogramsRequest, IHistogramsResponse } from '../../api/services/HistogramApi';
import { AuthContext } from '../../AuthContext';
import { SmallHistogram } from './SmallHistogram';
import { LoadableHistogram } from '../Histogram/LoadableHistogram';
import { Histogram, IHistogramBin } from '../Histogram/Histogram';
import { Tag } from '../../api/models/Tag';
import { Spel } from '../../api/services/Spel';
import { TagFactory } from '../../api/models/TagFactory';
import { ITagType } from '../../api/models/TagType';

interface IProps {
  className?: string;
  filter: IFilter;
}

interface IState {
  histogramData: IHistogramsResponse;
  loading: boolean;
  selectedTag: string;
  tags: Tag[];
}

class HistogramArrayBase extends React.Component<IProps, IState> {
  declare context: React.ContextType<typeof AuthContext>;
  private controller: AbortController = null;

  state: IState = {
    histogramData: null,
    loading: false,
    selectedTag: null,
    tags: this.props.filter.tags.map(t => t.clone())
  }

  componentDidMount = () => {
    this.load();
  }

  componentWillUnmount = () => {
    // Cancel any running Histogram API query.
    if(this.controller) this.controller.abort();
  }

  handleClick = (idx: number) => {
    this.setState({
      selectedTag: this.props.filter.tagTypeCollection.filter(t => t.tagClass == 'FLOAT')[idx].id
    });
  }

  load = () => {
    // If an AbortController instance is available, then cancel the pending
    // request.
    if(this.controller) this.controller.abort();

    // Create a new AbortController.
    this.controller = new AbortController();    

    this.setState({
      loading: true
    });

    // Build array of histograms:
    let histograms: {[ key: string]: { tagId: string } } = {};
    this.props.filter.tagTypeCollection
      .filter(t => t.tagClass == 'FLOAT')
      .forEach(tt => histograms[tt.id] = { tagId: tt.id })
    const request: IHistogramsRequest = {
      filter: Spel.getSpel(this.state.tags),
      histograms: histograms
    }

    HistogramApi.histogram(request, this.context.access_token, this.controller.signal)
    .then(response => {
      this.setState({
        histogramData: response,
        loading: false
      });
    })
  }

  filter = (tagId: string, bin: IHistogramBin) => {
    // Remove tag from state if present:
    this.state.tags = this.state.tags.filter(t => t.id !== tagId);
    
    // Get tag type:
    const tagType: ITagType = this.props.filter.tagTypeCollection.find(tt => tt.id === tagId);
    // Add tag:
    const tag = TagFactory.createTag(tagType);
    tag.setValues([bin.binStart.toString(), bin.binEnd.toString()]);

    // Add tag to state:
    this.state.tags.push(tag);
    this.setState({
      tags: this.state.tags
    });
    this.load();
  }

  render = () => {
    const p = this.props;
    return (
      <div className={p.className}>
        <SmallHistogramsList>
        {this.props.filter.tagTypeCollection
          .filter(t => t.tagClass == 'FLOAT')
          .map((t, idx) => <SmallHistogram 
            key={idx}
            loading={this.state.loading}
            title={t.name}
            bins={this.state.histogramData == null ? [{}, {}, {}, {}, {}] : this.state.histogramData.results[t.id]} 
            onClick={() => this.handleClick(idx)}
          />)
        }
        </SmallHistogramsList>
        <BigHistogram>
          {this.state.selectedTag == null && <Message type="info">Please select a histogram above.</Message>}
          {this.state.selectedTag != null && 
            <Histogram
              loading={this.state.loading}
              bins={this.state.histogramData.results[this.state.selectedTag]} 
              selectionStart={0} 
              selectionLength={0}
              showAxis
              showValues
              onClick={(idx) => this.filter(this.state.selectedTag, this.state.histogramData.results[this.state.selectedTag][idx])}
            /> 
          }
        </BigHistogram>
      </div>
    );
  }
}

HistogramArrayBase.contextType = AuthContext;

const HistogramArrayStyled = styled(HistogramArrayBase)`
  position: absolute;
  z-index: 100;
  right: 32px;
  top: 32px;
  bottom: 124px;
  width: 40vw;
  border-radius: 4px;
  background: white;
  border: solid 1px #aaa;
  box-sizing: border-box;
  padding: 8px;

  display: flex;
  flex-direction: column;
`

const SmallHistogramsList = styled('div')`
  flex: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow-y: auto;
  align-content: flex-start;
`

const BigHistogram = styled('div')`
  flex: 0;
  height: 300px;
  min-height: 300px;
  border: solid 1px #aaa;
  border-radius: 4px;
  margin: 16px 5px 5px 5px;
  padding: 8px;
`

class HistogramArray extends React.Component {
  render = () => {
    return (
      <FilterContext.Consumer>
        {filter => <HistogramArrayStyled filter={filter}/>}
      </FilterContext.Consumer>
    );
  }
}

export { HistogramArray }

