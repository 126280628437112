import type { CircleLayer } from 'react-map-gl';
import { CLUSTER_SIZE, CLUSTER_SIZE_STEP } from './ClusterConstants';

const ClustersStrokeLayer: CircleLayer = {
  id: "clusters-stroke",
  type: "circle",
  paint: {
    "circle-radius": [
      "+", 
        ["*", 
          [ "log10", [ "get", "count" ] ], 
          CLUSTER_SIZE_STEP], 
      CLUSTER_SIZE
    ],
    "circle-opacity": 0,
    "circle-color": "transparent",
    "circle-stroke-color": "white",
    "circle-stroke-opacity": 0.5,
    "circle-stroke-width": 4
  }
};

export { ClustersStrokeLayer }
