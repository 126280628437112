import * as React from 'react';

import Map, { Layer, MapboxMap, MapLayerMouseEvent, Popup, ScaleControl, Source } from 'react-map-gl';
import { FeatureCollection } from "geojson"; 
import { IMapElement } from '../api/services/MapApi';

import { App } from '../App';
import { IFilter } from '../FilterContext';
import { ZoomInButton } from './widgets/ZoomInButton';
import { ZoomOutButton } from './widgets/ZoomOutButton';
import { ButtonList } from './widgets/ButtonList';
import { PondsFillLayer } from './layers/PondsFillLayer';
import { PondsStrokeLayer } from './layers/PondsStrokeLayer';
import { PondsHoverLayer } from './layers/PondsHoverLayer';
import { ClustersCircleLayer } from './layers/ClustersCircleLayer';
import { ClustersStrokeLayer } from './layers/ClustersStrokeLayer';
import { ClustersSymbolLayer } from './layers/ClustersSymbolLayer';
import { ClustersStrokeHoverLayer } from './layers/ClustersStrokeHoverLayer';
import { InfoTable } from './widgets/InfoTable';
import { PondsSelectionLayer } from './layers/PondsSelectionLayer';

const MINZOOM = 3;       
const MAXZOOM = 17;
const MIN_POLYZOOM = 16;

interface IProps {
  elements: IMapElement[];
  selectedElements: string[];
  filter: IFilter;
  onLoadData: (upperLeft: number[], lowerRight: number[], zoom: number) => void;
  onSelect: (id: string, additive: boolean) => void;
}

interface IState {
  hoverId: number | string;                // ID of currently-hovered feature (-1 for none)
  hoverIsPolygon: boolean;                 // Is the hovered feature a polygon (not a cluster)?
  hoverLatitude: number;                   // Hover latitude
  hoverLongitude: number;                  // Hover longitude
  hoverData: IMapElement,                  // Map element associated with hovered feature
  cursor: 'auto' | 'pointer';
  polygonCollection: FeatureCollection;
  clusterCollection: FeatureCollection;
}

class MapView extends React.Component<IProps, IState> {
  private map: MapboxMap = null;

  state: IState = {
    hoverId: -1,
    hoverIsPolygon: false,
    hoverLatitude: null,
    hoverLongitude: null,
    hoverData: null,
    cursor: 'auto',
    polygonCollection: null,
    clusterCollection: null
  };

  componentDidUpdate = (prevProps: IProps) => {
    // Update map only if filter has changed.
    if(this.props.filter != prevProps.filter) this.getData();
    // Update GeoJSON only if elements have changed:
    if(this.props.elements != prevProps.elements) this.updateGeoJSON();
  }

  handleLoad = (e: mapboxgl.MapboxEvent) => {
    this.map = e.target;
    this.getData();
  }

  handleMoveEnd = () => {
    this.getData();
  }

  getData = () => {
    const bounds: mapboxgl.LngLatBounds = this.map.getBounds();
    this.props.onLoadData(
      [ bounds.getWest(), bounds.getNorth() ],
      [ bounds.getEast(), bounds.getSouth() ],
      this.map.getZoom()
    );
  }

  //
  // Convert map elements into FeatureCollection.
  //
  updateGeoJSON = () => {
    this.setState({
      polygonCollection: {
        type: 'FeatureCollection',
        features: this.props.elements.filter(e => e.geometry.type == 'MultiPolygon').map((e, idx) => { return {
            type: 'Feature',
            geometry: e.geometry,
            properties: { id: e.id, type: 'pond' }
          }; 
        })
      },
      clusterCollection: {
        type: 'FeatureCollection',
        features: this.props.elements.filter(e => e.geometry.type == 'Point').map((e, idx) => { return {
            type: 'Feature',
            geometry: e.geometry,
            properties: { id: idx, count: e.clusterCount, type: 'cluster' }
          }; 
        })
      }      
    });
  }

  handleZoomIn = () => {
    this.map.zoomIn();
  }

  handleZoomOut = () =>{
    this.map.zoomOut();
  }

  handleClick = (e: MapLayerMouseEvent) =>  {
    if(e.features.length == 0) return;
    if(e.features[0].properties.type != 'pond') return;
    this.props.onSelect(e.features[0].properties.id, e.originalEvent.ctrlKey);
  }

  handleDoubleClick = (e: MapLayerMouseEvent) => {
    if(e.features.length == 0) return;
    if(e.features[0].properties.type != 'cluster') return;
    this.map.easeTo({
      center: e.lngLat,
      zoom: Math.min(this.map.getZoom() + 1, MAXZOOM)
    });
  }

  handleMouseMove = (e: MapLayerMouseEvent) => {
    // If source isn't yet loaded, MapGL throws an error:
    if(!this.map || !this.map.loaded) return;

    // Turn hovering off:
    this.setState({ hoverId: -1, cursor: 'auto' });
    if(e.features.length == 0) return;

    // If hovering over a feature, set hover to feature's id property.
    const featureID = e.features[0].properties.id;
    
    this.setState({
      hoverId: featureID,
      hoverIsPolygon: e.features[0].geometry.type == 'Polygon',
      hoverLatitude: e.lngLat.lat,
      hoverLongitude: e.lngLat.lng,
      hoverData: this.props.elements.find(e => e.id == featureID),
      cursor: 'pointer'
    });
  }

  render = () => {
    return (
      <>
        <ButtonList>
          <ZoomOutButton minzoom={MINZOOM} zoom={this.map ? this.map.getZoom() : 0} onClick={this.handleZoomOut}/>
          <ZoomInButton  maxzoom={MAXZOOM} zoom={this.map ? this.map.getZoom() : 0} onClick={this.handleZoomIn}/>
        </ButtonList>
        <Map
          ref={(el:any) => this.map = el}
          mapboxAccessToken={App.config.mapKey}
          initialViewState={{ longitude: 114.35860276862002, latitude: -8.482077584994926, zoom: 15 }}
          style={{width: '100%', height: '100%'}}
          logoPosition="top-right"  // Moves mapbox logo
          fadeDuration={0}          // Prevents fading in of cluster labels
          interactiveLayerIds={["cluster-circles", "ponds-fill"]}
          cursor={this.state.cursor}
          mapStyle="mapbox://styles/longlineenvironment/cl8x4af5v003n14ld74f6ugwd"
          minZoom={MINZOOM}
          maxZoom={MAXZOOM}
          doubleClickZoom={false}
          //Events
          onLoad={this.handleLoad}
          onMoveEnd={this.handleMoveEnd}
          onClick={this.handleClick}
          onDblClick={this.handleDoubleClick}
          onMouseMove={this.handleMouseMove}
        >
          {/* Controls */}
          {/* <NavigationControl visualizePitch={true} position="top-left"/> */}
          <ScaleControl position="bottom-right"/>

          {/* Layers */}
          <Source id="satellite" type="raster" url="mapbox://mapbox.satellite" tileSize={512}>
            <Layer id="satellite" type="raster"/>
          </Source>
          <Source id="ponds" type="geojson" data={this.state.polygonCollection}>
            <Layer minzoom={MIN_POLYZOOM} {...PondsFillLayer}/>
            <Layer minzoom={MIN_POLYZOOM} {...PondsHoverLayer} filter={['==', ['get', 'id'], this.state.hoverId]}/>
            <Layer minzoom={MIN_POLYZOOM} {...PondsSelectionLayer} filter={['in', ['get', 'id'], ["literal", this.props.selectedElements]]}/>
            <Layer minzoom={MIN_POLYZOOM} {...PondsStrokeLayer}/>
          </Source>
          <Source id="clusters" type="geojson" data={this.state.clusterCollection}>
            <Layer maxzoom={MIN_POLYZOOM-1} {...ClustersCircleLayer}/>
            <Layer maxzoom={MIN_POLYZOOM-1} {...ClustersStrokeLayer} filter={['!=', ['get', 'id'], this.state.hoverId]}/>
            <Layer maxzoom={MIN_POLYZOOM-1} {...ClustersStrokeHoverLayer} filter={['==', ['get', 'id'], this.state.hoverId]}/>
            <Layer maxzoom={MIN_POLYZOOM-1} {...ClustersSymbolLayer}/>
          </Source>
          {this.state.hoverId != -1 && this.state.hoverIsPolygon && 
          <Popup
            longitude={this.state.hoverLongitude}
            latitude={this.state.hoverLatitude}
            offset={[0, -10]}
            closeButton={false}
            maxWidth="290px"
          >
            <InfoTable element={this.state.hoverData}/>
          </Popup>}
        </Map>
      </>
    );
  }
}

export { MapView }
