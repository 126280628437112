import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme'
import { css } from 'styled-components';
import { keyframes } from 'styled-components'

import { Number } from '@independent-software/typeui/formatters/Number';

// Constants:
const TRANSITION_TIME    = 0.2; // seconds
const BAR_COLOR          = "#DBA12F";
const BAR_COLOR_SELECTED = "#F5C453";
const BAR_COLOR_LOADING  = "#dddddd";
const BAR_GAP            = 2; // Gap on either side of a bar, in px
const HOVER_OPACITY      = 0.5;
const MIN_HEIGHT         = 3; // Minimum bar height, in %.

interface IProps {
  /** @ignore */
  className?: string;
  /** Absolute bar value. */
  value: number;       
  /** Height must be a percentage. */
  height: number;      
  /** Is this bar selected? */
  selected: boolean;   
  /** Show values on hover? */
  showValues?: boolean;
  /** Currently loading? */
  loading?: boolean;
  /** Fired if bar is clicked. */
  onClick?: () => void;
}

class HistogramBarBase extends React.Component<IProps> {
  render = () => {
    const p = this.props;
    return (
      <div className={p.className} onClick={p.onClick}>
        <HistogramBarText>
          {p.showValues && <Number value={Math.round(p.value)} decimals={0}/>}
        </HistogramBarText>
      </div>
    );
  }
}

const HistogramBarText = styled('div')`
  position: absolute;
  bottom: 10px;
  left: calc(50% - 14px);
  text-align: center;
  writing-mode: vertical-lr;
  transform: rotate(-180deg) scale(0.001);
  user-select: none;
  z-index: 2;
  transition: transform ease-in-out ${TRANSITION_TIME}s, bottom ease-in-out ${TRANSITION_TIME}s;
  user-select: none;
`

const loadingAnimation = keyframes`
 0%   { height: ${MIN_HEIGHT}%; }
 50%  { height: 100%; }
 100% { height: ${MIN_HEIGHT}%; }
`

const HistogramBar = styled(HistogramBarBase)`
  position:   relative;
  height:     100%;
  flex:       1;
  background-color: transparent;
  margin: 0 ${BAR_GAP}px 0 ${BAR_GAP}px;

  /** Cursor is only changed if onClick handler is provided and loading is complete: */
  cursor: ${p => p.onClick && !p.loading ? "pointer" : null};

  /** :after is the actual bar. */
  transition: background-color ease-in-out ${TRANSITION_TIME}s;
  &:after {
    content: '';
    position: absolute;
    z-index: 1;
    left: 0;
    bottom: 0;
    width: 100%;
    height: ${p => p.height}%;
    min-height: ${MIN_HEIGHT}%;
    background-color: ${p => p.loading ? BAR_COLOR_LOADING : (p.selected ? BAR_COLOR_SELECTED : BAR_COLOR)};
    opacity: 1;
    transition: height ease-in-out 1s, background-color ease-in-out 1s, opacity ease-in-out ${TRANSITION_TIME}s;

    ${p => p.loading && css`
      height: 50%;
    `}
  }

  /** Hover effect only if clickable and loaded. */
  ${p => p.onClick && !p.loading && css`
    &:hover {
      background-color: #f3f3f3;
      ${HistogramBarText} {
        /* Text is rotated 180deg to turn writing-mode around: */
        transform: rotate(-180deg) scale(1);
        bottom: 40px;
      }
    }
    /* Hovering over :after */
    &:hover {
      &:after {
        opacity: ${HOVER_OPACITY};
      }  
    }
  `}
`;

export { HistogramBar }
