import * as React from 'react';
import { Tag } from '../api/models/Tag';
import { TagFactory } from '../api/models/TagFactory';
import { ITagType } from '../api/models/TagType';
import { TagApi } from '../api/services/TagApi';
import { AuthContext } from '../AuthContext';
import { FilterContext, IFilter } from '../FilterContext';
import { Dialog } from '@independent-software/typeui/controls/Dialog';

interface IProps {
  children?: React.ReactNode;
}

class Filter extends React.Component<IProps, IFilter> {
  declare context: React.ContextType<typeof AuthContext>

  constructor(props: IProps) {
    super(props);
    this.state = {
      tagTypeCollection: [],
      tags: [],
      currentTag: null,
      mode: null,      
      addTag: this.handleAddTag,
      editTag: this.handleEditTag,
      removeTag: this.handleRemoveTag
    };
  }

  componentDidMount = () => {
    this.loadTagTypes();
  }  

  loadTagTypes = async () => {
    this.setState({
      tagTypeCollection: await TagApi.listTagTypes(this.context.access_token)
    });
  }


  handleAddTag = (tagType: ITagType) => {
    this.setState({
      mode: 'add',
      currentTag: TagFactory.createTag(tagType)
    });
  }

  handleEditTag = (tag: Tag) => {
    this.setState({
      mode: 'edit',
      currentTag:  tag.clone()
    }, () => {
      tag.description = "Original";
    });
  }

  handleRemoveTag = (tag: Tag) => {
    this.setState({
      tags: this.state.tags.filter(t => t !== tag)
    })
  }  

  handleConfirm = () => {
    if(this.state.mode == 'add') {
      this.setState({
        tags: [ ...this.state.tags, this.state.currentTag ]
      });
    }

    if(this.state.mode == 'edit') {
      this.setState({
        tags: [ ...this.state.tags.filter(t => t.id !== this.state.currentTag.id), this.state.currentTag ]
      });
    }

    this.setState({
      mode: null,
      currentTag: null
    });
  }

  handleCancel = () => {
    this.setState({
      mode: null,
      currentTag: null
    });
  }

  render = () => {
    return (
      <FilterContext.Provider value={this.state}>
        {this.props.children}
        <Dialog open={this.state.mode != null} onClose={this.handleCancel}>
          {this.state.mode != null && <>
            {this.state.currentTag.getDialog(this.state.tags, this.handleConfirm, this.handleCancel)}
          </>}
        </Dialog>
      </FilterContext.Provider>
    );
  }
}

Filter.contextType = AuthContext;

export { Filter }
