import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme'
import { Icon } from '@independent-software/typeui/controls/Icon';

interface IProps {
  /** @ignore */
  className?: string;
  /** Fired when FilterButton is clicked. */
  onClick: () => void;
}

/**
 * The FilterButton opens the tag list when clicked.
 */
class FilterButtonBase extends React.Component<IProps> {
  render = () => {
    const p = this.props;
    return (
      <div id="filterbutton" className={p.className} onClick={p.onClick}>
        <Icon url="sprites.svg#tag"/>
        <span>Filter by tag</span>
      </div>
    );
  }
}

const FilterButton = styled(FilterButtonBase)`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 250px;
  box-sizing: border-box;
  padding: 0 10px 0 10px;
  cursor: pointer;
  user-select: none;
  white-space: nowrap;
  min-width: 200px;
  span {
    margin-left: 5px;
  }
  transition: background-color ease-in-out 0.3s;
  &:hover {
    background: #efefef;
  }
`

export { FilterButton }
