import * as React from 'react';

interface IAuth {
  id: string;
  name: string;
  email: string;
  access_token: string;
  refresh_token: string;
  rights: string[];
  signin: (id: string, name: string, email: string, access_token: string, refresh_token: string, rights: string[]) => void;
  signout: () => void;
}

const AuthContext = React.createContext<IAuth>({
  id: null,
  name: null,
  email: null,
  access_token: null,
  refresh_token: null,
  rights: [],
  signin: () => {},
  signout: () => {}
});

export { AuthContext, IAuth }
