import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme';
import { css } from 'styled-components';
import { Icon, IconType } from '@independent-software/typeui/controls/Icon';

interface IProps {
  className?: string;
  
  hint?: React.ReactNode; // Optional hint to show on mouseover.
  
  icon?: IconType;        // Icon to show on button
  url?: string;           // Icon URL to show on button
  active?: boolean;       // Show button as active
  disabled?: boolean;     // Show button as disabled
  onClick: () => void;    // onClick callback
}

class MapButtonBase extends React.Component<IProps> {
  render = () => {
    const p = this.props;
    return (
      <div className={p.className} onClick={p.disabled ? null : p.onClick}>
        {p.url &&  <Icon size="large" url={"sprites.svg#" + p.url}/>}
        {p.icon && <Icon name={p.icon}/>}
        {p.hint && <Hint>{p.hint}</Hint>}
      </div>
    )
  }
}

const Hint = styled('div')`
  transform: scale(0);
  transition: transform ease-in-out 0.1s;
  position: absolute;
  left: 50px;
  background: #333;
  height: 24px;
  line-height: 24px;
  font-size: 12px;
  color: #fff;
  padding: 0 12px 0 6px;
  border-radius: 4px;
  white-space: nowrap;
  &:after {
    z-index: -1;
    position: absolute;
    content: '';
    left: -8px;
    top: 3px;
    width: 18px;
    height: 18px;
    background: #333;
    transform: rotate(45deg);
  }
`

const MapButton = styled(MapButtonBase)`
  /* Position control */
  z-index: 10001;
  width: 34px;
  height: 34px;

  /* Center icon in control */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  box-sizing: border-box;
  background-clip: padding-box;

  border: 2px solid #cfcfcf;
  border-radius: 4px;
  background: #333;
  svg { fill: white; }

  ${p => p.active && css`
    border-color: #cfcfcf;
    background-color: white;
    svg { fill: #333; }
  `}

  ${p => p.disabled && css`
    border-color: #222222;
    svg { fill: #afafaf; }
  `}

  ${p => !p.disabled && css`
    &:hover {
      border-color: white;
      ${Hint} {
        transform: scale(1);
      }
    }
  `}
`

export { MapButton }
