import type { LineLayer } from 'react-map-gl';

const PondsStrokeLayer: LineLayer = {
  id: "ponds-line",
  type: "line",
  minzoom: 15,
  paint: { 
    "line-color": "white",
    "line-width": 1
  }
};

export { PondsStrokeLayer }
