import * as React from 'react';

interface IProps {
  children?: React.ReactNode;
}

class ButtonList extends React.Component<IProps> {
  render = () => {
    return (
    <>
      {React.Children.map(this.props.children, (child:any, idx: number) => {
        return <div style={{ position: "absolute", zIndex: 9999, left: "32px",bottom: (40 + idx * 48)+ "px" }}>
          {child}
        </div>
      })}
      </>
    );
  }
}

export { ButtonList }
