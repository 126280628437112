import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme'

import { AuthContext } from '../AuthContext';
import { MapView } from './MapView';
import { TagBar } from '../components/TagBar/TagBar';
import { IMapElement, IMapSearchRequest, MapApi } from '../api/services/MapApi';
import { FilterContext, IFilter } from '../FilterContext';
import { Spel } from '../api/services/Spel';
import { LogoBar } from '../components/LogoBar/LogoBar';
import { HistogramArray } from '../components/HistogramArray/HistogramArray';
import { Icon } from '@independent-software/typeui/controls/Icon';

interface IProps {
  className?: string;
}

interface IState {
  showHistogramArray: boolean;
  elements: IMapElement[];
  selectedElements: string[];
}

class MapPageBase extends React.Component<IProps> {
  declare context: React.ContextType<typeof AuthContext>
  private controller: AbortController = null;

  state: IState = {
    showHistogramArray: false,
    elements: [],
    selectedElements: []
  }

  handleLoadData = (filter: IFilter, upperLeft: number[], lowerRight: number[], zoom: number) => {
    // If an AbortController instance is available, then cancel the pending
    // request.
    if(this.controller) this.controller.abort();
    // Create a new AbortController.
    this.controller = new AbortController();

    const request: IMapSearchRequest = {
      upperLeft: upperLeft, 
      lowerRight: lowerRight,
      zoom: zoom,
      filter: Spel.getSpel(filter.tags),
      returnTags: [ 
        "::ADM0_EN", "::ADM1_EN", "::ADM2_EN", "::ADM3_EN", "::ADM4_EN", 
        "::area_m2", "::species", "::company", "::owner", "::farm_name", "::vol_m3", "::culture_type", "::structure_type", "::use"
      ]
    };
    MapApi.mapSearch(request, this.context.access_token, this.controller.signal)
    .then(res => this.setState({ elements: res.features }))
    .catch((error: Error) => {
      // Show error only if it isn't a "canceled" error (which is normal).
      if(error.message != 'canceled') {
        console.log("Map search API error:", error);
      }
    }); 
  }

  handleToggleHistogramArray = () => {
    this.setState({
      showHistogramArray: !this.state.showHistogramArray
    });
  }

  handleSelect = (id: string, additive: boolean) => {
    let selection = this.state.selectedElements;
    if(!additive) selection = [];
    if(selection.indexOf(id) == -1) {
      selection = [...selection, id]
    } else {
      selection = selection.filter(e => e !== id)
    }
    this.setState({ selectedElements: selection });
  }

  render = () => {
    const p = this.props;
    return (
      <div className={p.className}>
        <FilterContext.Consumer>
          {filter => 
            <MapHolder>
              <MapView 
                filter={filter} 
                elements={this.state.elements} 
                selectedElements={this.state.selectedElements} 
                onSelect={this.handleSelect}
                onLoadData={(...a) => this.handleLoadData(filter, ...a)}
              />
            </MapHolder>
          }
        </FilterContext.Consumer>
        <TagBarHolder>
          <TagBar/>
          <HistogramArrayButtonHolder>
            <Icon name="bar-chart" onClick={this.handleToggleHistogramArray}/>
          </HistogramArrayButtonHolder>
        </TagBarHolder>
        <LogoBarHolder>
          <LogoBar/>
        </LogoBarHolder>
        {this.state.showHistogramArray == true && <HistogramArray/>}
      </div>
    );
  }
}

MapPageBase.contextType = AuthContext;

const MapPage = styled(MapPageBase)`
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: #333;
`

const MapHolder = styled('div')`
  position: relative;
  flex: 1;
`

const TagBarHolder = styled('div')`
  position: relative;
  display: flex;
  align-items: center;
  flex: 0;
  background: white;
`

const HistogramArrayButtonHolder = styled('div')`
  padding: 0 10px 0 10px;
`

const LogoBarHolder = styled('div')`
  position: relative;
  flex: 0;
`

export { MapPage }
