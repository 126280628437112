import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme'
import { css } from 'styled-components';

import { Icon, IconStyled } from '@independent-software/typeui/controls/Icon';
import { ITagType } from '../../api/models/TagType';

interface IProps {
  /** @ignore */
  className?: string;
  /** Tag type for this entry. */
  tagType: ITagType; 
  /** Search string. The matching part in the tag name will be highlighted. */
  q: string;
  /** Is this the currently keyboard-selected tag? */
  highlight: boolean;
  /** Is this tag already in the filter? */
  selected: boolean;
  /** Fired when tag name is clicked. */
  onClick: () => void;
  /** Fired when categorization icon is clicked. */
  onCategorize: () => void;
}

class TagListEntryBase extends React.Component<IProps> {
  private wrapperRef: HTMLDivElement;

  componentDidUpdate = (prevProps: IProps) => {
    // If this entry is newly selected by keyboard, then scroll it into view:
    if(prevProps.highlight == false && this.props.highlight && this.wrapperRef != null) {
      this.wrapperRef.scrollIntoView(true);
    }
  }

  //
  // Convert tag name into a list of JSX elements. Occurrences of the
  // search string are <strong> elements; ordinary text is <span>.
  //
  private highlightSearch = (): React.ReactElement[] => {
    let elems: React.ReactElement[] = [];
    // If there is a search string...
    if(this.props.q != "") {
      // Split by search string:
      let s = this.props.tagType.name.replace(new RegExp(this.props.q, "i"), "@@@$&@@@");
      let parts: string[] = s.split("@@@");
      // Make every 2nd element bold:
      for(let i = 0; i < parts.length; i++) {
        if(i % 2 == 0) 
          elems.push(React.createElement("span", { key: i }, parts[i]));  
        else 
          elems.push(React.createElement("strong", { key: i }, parts[i]));  
      }
    } 
    // No search string, do nothing:
    else {
      elems = [ React.createElement("span", { key:  0 }, this.props.tagType.name)];
    }
    return elems;
  }

  handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    this.props.onCategorize();
  }

  render = () => {
    const p = this.props;
    return (
      <div className={p.className} ref={(el:any) => this.wrapperRef = el} onClick={p.selected ? null : this.props.onClick}>
        <TagName>
          {this.highlightSearch()}
        </TagName>
        {/* Only FLOAT tags can be categorized */}
        {p.tagType.tagClass == "FLOAT" && <CategorizeHolder>
          <Icon name="bar-chart" onClick={this.handleClick as any}/>
        </CategorizeHolder>}
      </div>
    );
  }
}

const TagListEntry = styled(TagListEntryBase)`
  position: relative;
  background: #fff;
  &:nth-child(2n) {
    background: #f3f3f3;
  }
  ${p => !p.selected && css`
    cursor: pointer;
    &:hover {
      background: #cccccc;
    }
  `}
  /* Highlight keyboard-selected tag */
  ${p => p.highlight && css`
    background: black !important;
    color: white;
    ${IconStyled} {
      fill: white;
    }
  `}
  strong {
    color: #000;
    font-weight: 500;
    background: lightblue;
    border-radius: 3px;
    border: solid 1px steelblue;
  }
  ${p => p.selected && css`
    color: #aaa;
  `}
`

const TagName = styled('div')`
  padding: 0 50px 0 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const CategorizeHolder = styled('div')`
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export { TagListEntry }
