import * as React from 'react';

import { Dialog } from '@independent-software/typeui/controls/Dialog';
import { Button } from '@independent-software/typeui/controls/Button';
import { Form } from '@independent-software/typeui/controls/Form';
import { Input } from '@independent-software/typeui/controls/Input';

import { Tag } from '../models/Tag';

interface IProps {
  className?: string;
  tags: Tag[];
  tag: Tag;
  onConfirm: (value: any[]) => void; // Complete edit with these values.
  onCancel: () => void;
}

interface IState {
  value: any[];
}

class ShortTextDialog extends React.Component<IProps, IState> {

  constructor(props: IProps) {
    super(props);
    this.state = {
      value: props.tag.value.slice()
    }
  }

  private handleOK = () => {
    if(this.props.tag.isValid(this.state.value)) {
      this.props.onConfirm(this.state.value);
    }
  }

  // If a key is pressed, over the entire Modal, then react to it.
  private handleKeyDown = (e: React.KeyboardEvent) => {
    if(e.code == "Enter") this.handleOK();
    if(e.code == "Escape") this.props.onCancel();
  }  

  private handleChangeValue = (index: number, s: string) => {
    this.state.value[index] = s;
    this.setState({
      value: this.state.value
    });
  }  

  render = () => {
    const p = this.props;
    return (
      <div className={p.className} onKeyDown={this.handleKeyDown}>
        <Dialog.Header>{p.tag.name}</Dialog.Header>

        <Dialog.Content>
          <Form.Uncontrolled label="Tag value" hint="Please enter a text value for matching.">
            <Input placeholder="Enter text" value={this.state.value[0]} error={this.state.value[0] != "" && !p.tag.isValueValid(this.state.value[0])} onChange={(s) => this.handleChangeValue(0,s)}/>
          </Form.Uncontrolled>
        </Dialog.Content>

        <Dialog.Footer>
          <div style={{ display: 'flex', justifyContent: 'space-between'}}>
            <Button onClick={p.onCancel}>Cancel</Button>
            <Button primary disabled={!p.tag.isValid(this.state.value)} onClick={this.handleOK}>OK</Button>
          </div>
        </Dialog.Footer>
      </div>
    );
  }
}

export { ShortTextDialog }
