import axios from 'axios';
import { App } from '../../App'

// {"error":"invalid_client","error_description":"INVALID_CREDENTIALS: Invalid client credentials"}
// {"access_token":"eyJ...Wjcw",
// "expires_in":36000,
// "refresh_expires_in":1800,
// "refresh_token":"eyJh...Gj9Q",
// "token_type":"Bearer",
// "not-before-policy":0,
// "session_state":"4fa12482-cfc3-44be-b5ee-3cfcb8549439",
// "scope":"profile email"}
interface IKeycloakResponse {
  access_token: string;
  expires_in: number;
  refresh_expires_in: number;
  refresh_token: string;
  token_type: string;
  session_state: string;
  scope: string;
}

interface IKeycloakError {
  error: string;
  error_description: string;
}

class SigninApi {

  static signin = async (username: string, password: string): Promise<IKeycloakResponse> => {
    // Build request parameters (form URL encoded);
    const params = new URLSearchParams();
    params.append('grant_type', 'password');
    params.append('username', 'alex.vanoostenrijk@gmail.com'); 
    params.append('password', password);
    // params.append('password', 'PassW0rd!123'); // PassW0rd!123
    params.append('client_id', 'aquascape');

    // Build request headers:
    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }
    return axios.post<IKeycloakResponse>(`${App.config.authURL}`, params, config)
    .then((res) => res.data)
    .catch((error) => {
      if(error.response) {
        throw new Error((error.response.data as IKeycloakError).error_description + ".");
      } else if(error.request) {
        throw new Error("No server response was received.");
      } else {
        throw new Error("There was a problem sending your request.");
      }
    });
  }
}

export { SigninApi }
