import { ITagType } from "./TagType";
import { Tag } from "./Tag";
import { FloatTag } from "./FloatTag";
import { ShortTextTag } from "./ShortTextTag";
//import { NavTag } from "./NavTag";

class TagFactory {
  static createTag = (type: ITagType): Tag => {
    switch(type.tagClass) {
      case 'FLOAT':
        return new FloatTag(
          type.id, type.name, type.description, 
          type.defaultDouble, 
          type.defaultDoubleRange ? type.defaultDoubleRange.start : null, 
          type.defaultDoubleRange ? type.defaultDoubleRange.end : null);
      case 'SHORT_TEXT':
        return new ShortTextTag(
          type.id, type.name, type.description, type.defaultStr);
      //case 'NAV':
        //return new NavTag();
      default:
        throw("Unknown tag class: " + type.tagClass);
    }
  }
}

export { TagFactory }
