import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme'

import { FilterTypein } from './FilterTypein';
import { FilterButton } from './FilterButton';
import { TagList } from './TagList';
import { ITagType, TTagTypeCollection } from '../../api/models/TagType';
import { Tag } from '../../api/models/Tag';

interface IProps {
  /** @ignore */
  className?: string;
  /** Available tags. */
  tagTypeCollection: TTagTypeCollection; 
  /** Selected tags. */
  tags: Tag[];
  /** Fired when a tag is selected from the list. */
  onAddTag: (tagType: ITagType) => void;
  /** Fired when the categorization icon is clicked for a tag in the list. */
  onCategorizeTag: (tagTag: ITagType) => void;
}

interface IState {
  q: string;
  open: boolean;
  // Should the TagList open upwards?
  upwards: boolean;
  selectionIndex: number;
}

class TagSelectorBase extends React.Component<IProps, IState> {
  private wrapperRef: HTMLDivElement;

  state = { 
    q: "",
    open: false,
    upwards: true,
    selectionIndex: -1
  };

  componentDidMount() {
    // Listen for document-wide mousedown event when selector mounts.
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    // Clean up document-wide mousedown event when selector unmounts.
    document.removeEventListener('mousedown', this.handleClickOutside);
  }
  
  // Handle document-wide mousedown event by closing the list.
  handleClickOutside = (event: MouseEvent) => {
    let elem:Element = event.target as Element;
    if (this.wrapperRef && !this.wrapperRef.contains(elem)) {
      this.setState({ open: false });
    }
  }

  handleClickTag = (tagType: ITagType) => {
    this.setState({
      q: "",
      open: false
    });
    this.props.onAddTag(tagType);
  }

  handleOpen = () => {
    // Determine if TagList should open upwards:
    let below = this.wrapperRef.getBoundingClientRect().top > window.innerHeight / 2;
    this.setState({
      q: "",
      open: true,
      upwards: below,
      selectionIndex: -1
    });
  }

  //
  // Retrieve a list of tag types filtered by the search query.
  //
  private getFilteredTagTypes = () => {
    return this.props.tagTypeCollection.filter(tag => tag.name.toLowerCase().includes(this.state.q.toLowerCase()));
  }

  //
  // Handle search input. If the search query changes, then the keyboard
  // selection is reset to index -1.
  //
  private handleChange = (q: string) => {
    this.setState({
      q: q,
      selectionIndex: -1
    })
  }

  // 
  // If an up/down arrow key was pressed in the filter type in, then move
  // the selection index up or down.
  // 
  private handleArrow = (dir: number) => {
    // Move index:
    let idx = this.state.selectionIndex + dir;
    // Clamp index to filtered tags list:
    if(idx < 0) idx = 0;
    const max = this.getFilteredTagTypes().length;
    if(idx >= max) idx = max - 1;
    
    this.setState({
      selectionIndex: idx
    });
  }

  private handleEnter = () => {
    if(this.state.selectionIndex === -1) return;
    const tag = this.getFilteredTagTypes()[this.state.selectionIndex];
    this.setState({
      q: "",
      open: false
    });    
    this.props.onAddTag(tag);
  }

  private handleEscape = () => {
    this.setState({ open: false });
  }

  render = () => {
    const p = this.props;
    return (
      <div className={p.className} ref={(el:any) => this.wrapperRef = el}>
        {this.state.open == false && <FilterButton onClick={this.handleOpen}/>}
        {this.state.open == true && <>
          <FilterTypein 
            q={this.state.q} 
            onChange={this.handleChange}
            onArrow={this.handleArrow}
            onEnter={this.handleEnter}
            onEscape={this.handleEscape}
          />
        </>}
        <TagList 
          tagTypeCollection={this.getFilteredTagTypes()} 
          tags={p.tags}
          open={this.state.open} 
          upwards={this.state.upwards} 
          q={this.state.q} 
          selectionIndex={this.state.selectionIndex}
          onClickTag={this.handleClickTag}
          onCategorizeTag={p.onCategorizeTag}
        />
      </div>
    );
  }
}

const TagSelector = styled(TagSelectorBase)`
  position: relative;
  width: 250px;
  border-right: solid 1px #ccc;
`

export { TagSelector }
