import * as React from 'react';
import { Tag } from './api/models/Tag';
import { ITagType, TTagTypeCollection } from './api/models/TagType';

type TMode = 'add' | 'edit';

interface IFilter {
  /** Available tag types: */
  tagTypeCollection: TTagTypeCollection;
  /** Currently filtered tags and values: */
  tags: Tag[];
  /** Tag currently being added/edited: */
  currentTag: Tag;
  mode: TMode;

  addTag: (tagType: ITagType) => void;
  editTag: (tag: Tag) => void;
  removeTag: (tag: Tag) => void;
}

const FilterContext = React.createContext<IFilter>({
  tagTypeCollection: [],
  tags: [],
  currentTag: null,
  mode: null,
  addTag: () => {},
  editTag: () => {},
  removeTag: () => {}
});

export { FilterContext, IFilter }
