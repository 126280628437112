import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme'

import { Gravatar } from '@independent-software/typeui/controls/Gravatar'
import { Panel } from '@independent-software/typeui/controls/Panel'
import { Divider } from '@independent-software/typeui/controls/Divider'
import { Button } from '@independent-software/typeui/controls/Button'
import { AuthContext } from '../../AuthContext';

interface IProps {
  /** @ignore */
  className?: string;
}

interface IState {
  open: boolean;
}

class LogoBarBase extends React.Component<IProps, IState> {
  declare context: React.ContextType<typeof AuthContext>;
  state:IState = {
    open: false
  };

  handleClickAvatar = () => {
    this.setState({ open: true });
  }

  handleSignout = () => {
    this.context.signout();
  }

  render = () => {
    const p = this.props;
    return (
      <div className={p.className}>
        <Svg><use xlinkHref={"sprites.svg#aquascape"}></use></Svg>
        <div style={{position: 'relative'}}>
          <Gravatar email={this.context.email} onClick={this.handleClickAvatar}/>
          <Panel open={this.state.open} padded onClose={() => this.setState({ open: false })}>
            <Username>{this.context.email}</Username>
            <Divider/>
            <Buttons>
              <Button negative onClick={this.handleSignout}>Sign out</Button>
            </Buttons>
          </Panel>
        </div>
      </div>
    );
  }
}

LogoBarBase.contextType = AuthContext;

const Svg = styled('svg')`
  width: 150px;
  height: 30px;
`

const Username = styled('div')`
  font-weight: 500;
`

const Buttons = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`

const LogoBar = styled(LogoBarBase)`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px 0 10px;
  z-index: 5;
  height: 40px;
  min-height: 40px;
  background: white;
  box-sizing: border-box;
  box-shadow: 0 -1px 1px rgb(0,0,0, 0.5);
`

export { LogoBar }
