import * as React from 'react';
import { MapButton } from './MapButton';

interface IProps {
  maxzoom: number;
  zoom: number;
  onClick: () => void;
}

class ZoomInButton extends React.Component<IProps> {
  render = () => {
    const p = this.props;
    return (
      <MapButton url="zoom-in" disabled={p.zoom >= p.maxzoom} onClick={p.onClick} hint="Zoom in"/>
    );
  }
}

export { ZoomInButton }

