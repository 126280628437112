import * as React from 'react';

import { ToastService } from '@independent-software/typeui/controls/Toast';
import { Button } from '@independent-software/typeui/controls/Button'
import { Icon } from '@independent-software/typeui/controls/Icon'

import { AuthContext } from '../AuthContext';
import { SigninForm, ISigninForm } from './SigninForm';
import { SigninApi, UserApi } from '../api/services';

const BUTTON_TIMEOUT = 250; // ms

interface IState {
  signinForm: ISigninForm;
  loading: boolean;
  isFormValid: boolean;
}

class SigninComponent extends React.Component<{}, IState> {
  declare context: React.ContextType<typeof AuthContext>

  state: IState = {
    signinForm: { email: sessionStorage.getItem('email'), password: '' },
    loading: false,
    isFormValid: false
  }

  handleChange = (signinForm: ISigninForm, forceupdate: boolean) => {
    this.setState({
      signinForm: signinForm
    });
  }  

  handleValidate = (valid: boolean) => {
    this.setState({
      isFormValid: valid
    })
  }  

  handleSubmit = () => {
    this.setState({ loading: true });
    setTimeout(() => {
      SigninApi.signin('alex.vanoostenrijk@gmail.com', this.state.signinForm.password)
      .then(authData => {
        UserApi.me(authData.access_token)
        .then(userData => {
          this.setState({ loading: false });
          this.context.signin(userData.extId, userData.username, userData.email, authData.access_token, authData.refresh_token, []);
        });
      })
      .catch(error => {
        this.setState({ loading: false });
        ToastService.toast(error.message);
      })
    }, BUTTON_TIMEOUT);
    // Adds a 250ms timeout to make sure that signin button
    // animation actually gets seen.    
  }    

  render() {
    return (
      <div>
        <SigninForm disabled={this.state.loading} data={this.state.signinForm} onChange={this.handleChange} onValidate={this.handleValidate} onSubmit={this.handleSubmit}/>
        <div style={{display: 'flex', 'justifyContent': 'space-between'}}>
          <Button primary disabled={!this.state.isFormValid} onClick={this.handleSubmit}>
            {this.state.loading && <Icon loading name="circle-notch"/>}
            {!this.state.loading && "Sign in"}
            {this.state.loading && "Signing in..."}
          </Button>
        </div>
      </div>
    )
  }
}

SigninComponent.contextType = AuthContext;

export { SigninComponent };